:root {
  --cs-primary: #27aae1;
  --cs-soft-primary: rgba(19, 170, 225, 0.2);
  --cs-secondary: #444444;
  --cs-success: #34c38f;
  --cs-warning: #eb9e1a;
  --cs-danger: #f46a6a;
  --cs-soft-secondary: rgba(68, 68, 68, 0.2);
  --cs-soft-success: rgba(52, 195, 143, 0.18);
  --cs-soft-info: rgba(80, 165, 241, 0.18);
  --cs-soft-warning: rgba(241, 180, 76, 0.18);
  --cs-soft-white: rgba(240, 243, 245, 1);
  --cs-soft-danger: rgba(244, 106, 106, 0.25);
  --cs-soft-warning: rgba(235, 158, 26, 0.25);
  --cs-white: #ffffff;
  --cs-btn-padding-x: 0.75rem;
  --cs-btn-padding-y: 0.375rem;
  --cs-btn-font-size: 0.8125rem;
  --cs-btn-font-weight: 400;
  --cs-btn-line-height: 1.5;
  --cs-btn-bg: transparent;
  --cs-btn-border-width: 1px;
  --cs-btn-border-color: transparent;
  --cs-btn-border-radius: 0.25rem;
  --cs-btn-disabled-opacity: 0.65;
  --cs-btn-transittion: all 0.2s ease;
  --cs-dropdown-min-width: 10rem;
  --cs-dropdown-padding-x: 0;
  --cs-dropdown-padding-y: 0.5rem;
  --cs-dropdown-spacer: 0.125rem;
  --cs-dropdown-font-size: 0.8125rem;
  --cs-dropdown-color: #495057;
  --cs-dropdown-bg: #fff;
  --cs-dropdown-border-color: rgba(0, 0, 0, 0.15);
  --cs-dropdown-border-radius: 0.25rem;
  --cs-dropdown-border-width: 0;
  --cs-dropdown-inner-border-radius: calc(0.25rem - 0);
  --cs-dropdown-divider-margin-y: 0.5rem;
  --cs-dropdown-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  --cs-dropdown-link-color: #212529;
  --cs-dropdown-link-hover-color: #1e2125;
  --cs-dropdown-link-hover-bg: #f8f9fa;
  --cs-dropdown-link-active-color: #343a40;
  --cs-dropdown-link-active-bg: #f8f9fa;
  --cs-dropdown-link-disabled-color: #74788d;
  --cs-dropdown-item-padding-x: 1.5rem;
  --cs-dropdown-item-padding-y: 0.35rem;
  --cs-dropdown-header-color: #74788d;
  --cs-dropdown-header-padding-x: 1.5rem;
  --cs-dropdown-header-padding-y: 0.5rem;
  --cs-dropdown-divider-color: #eff2f7;
  --cs-card-border-radius: 0.25rem;
}

body {
  font-family: "Maven Pro", sans-serif;
  font-size: 0.8125rem;
  background: #fafafa;
}

select::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.text-primary {
  color: #27aae1;
}

.text-secondary {
  color: #444444;
}

.collapse:not(.show) {
  display: none;
}

.bg-primary {
  color: var(--cs-white);
  background-color: var(--cs-primary);
}

.bg-secondary {
  color: var(--cs-white);
  background-color: var(--cs-secondary);
}

.bg-light {
  color: var(--cs-primary);
  background-color: var(--cs-soft-white);
}

.bg-success {
  color: var(--cs-white);
  background-color: var(--cs-success);
}

.bg-warning {
  color: var(--cs-white);
  background-color: var(--cs-warning);
}

.bg-danger {
  color: var(--cs-white);
  background-color: var(--cs-danger);
}

.bg-soft-primary {
  color: var(--cs-primary);
  background-color: var(--cs-soft-primary);
}

.bg-soft-secondary {
  color: var(--cs-secondary);
  background-color: var(--cs-soft-secondary);
}

.bg-soft-success {
  color: var(--cs-success);
  background-color: var(--cs-soft-success);
}

.bg-soft-warning {
  color: var(--cs-warning);
  background-color: var(--cs-soft-warning);
}

.bg-soft-danger {
  color: var(--cs-danger);
  background-color: var(--cs-soft-danger);
}

/*
    Buttons
 */
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

button {
  display: inline-block;
  padding: var(--cs-btn-padding-y) var(--cs-btn-padding-x);
  font-size: var(--cs-btn-font-size);
  font-weight: var(--cs-btn-font-weight);
  line-height: var(--cs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: var(--cs-btn-transittion);
}

button.primary {
  color: var(--cs-primary);
  background-color: var(--cs-soft-primary);
}

button.primary:hover {
  color: var(--cs-white);
  background-color: var(--cs-primary);
}

button.secondary {
  color: var(--cs-secondary);
  background-color: var(--cs-soft-secondary);
}

button.secondary:hover {
  color: var(--cs-white);
  background-color: var(--cs-secondary);
}

button.soft-success {
  color: var(--cs-success);
  background-color: var(--cs-soft-success);
}

button.soft-success:hover {
  color: var(--cs-white);
  background-color: var(--cs-success);
}

/*
    Card
 */

.card {
  margin-bottom: 24px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.11) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  /*  border-radius: var(--cs-card-border-radius); */
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-header,
.card-footer {
  background-color: #fff;
}

.card-footer {
  border-top: 1px solid #ecf1f5;
}

.card-header {
  border-bottom: 1px solid #ecf1f5;
  padding: 0.75rem;
}

.card-title {
  font-size: 15px;
  font-weight: 600;
}

/*
    Forms
 */

.form-control {
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cs-secondary);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--bs-input-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 0.5rem;
}

.form-control.is-valid {
  border-color: #34c38f;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2334c38f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.form-control.is-invalid {
  border-color: #f46a6a;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.basic-single.is-invalid > .css-13cymwt-control,
.basic-multi-select.is-invalid > .css-13cymwt-control {
  border-color: #f46a6a;
  border-width: 1px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > :is(.form-control, .form-floating, .form-select) {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  padding-right: 30px;
}

.input-group > :is(.form-control.is-valid, .form-control.is-invalid) {
  padding-right: 50px;
}

.input-group > button {
  position: absolute;
  right: 0;
  margin-top: 0.5rem;
}

.input-group > :is(.is-invalid + button, .is-valid + button) {
  right: 20px;
}

.form-check {
  position: relative;
  text-align: left;
  display: block;
  min-height: 1.21875rem;
  padding-left: 1.5em;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-input-code {
  width: 70px;
  height: 50px;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.37rem 0.25rem;
}

/*
    DropDown
 */
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}

.dropdown-menu {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--cs-dropdown-min-width);
  padding: var(--cs-dropdown-padding-y) var(--cs-dropdown-padding-x);
  font-size: var(--cs-dropdown-font-size);
  color: var(--cs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--cs-dropdown-bg);
  background-clip: padding-box;
  border: var(--cs-dropdown-border-width) solid var(--cs-dropdown-border-color);
  border-radius: var(--cs-dropdown-border-radius);
}

.dropdown .dropdown-menu.show {
  top: 100% !important;
  display: block;
}
.dropup .dropdown-menu.show {
  bottom: 100% !important;
  display: block;
}

.dropdown-item {
  /* display: block; */
  width: 100%;
  padding: 0.35rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.nav-item .dropdown-item {
  display: block;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--cs-dropdown-divider-color);
  opacity: 1;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

/*
    TemplateDropdown Language
 */

.language > button {
  margin-bottom: 4px;
}

.language .dropdown-item {
  display: flex;
  align-items: center;
}

.language button img {
  height: 17px;
}

.language .dropdown-item img {
  height: 12px;
}

/*
    NavBard
 */
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--cs-primary);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/*
    Table
 */

table {
  border: 1px solid #eff2f7 !important;
  caption-side: bottom;
}

table :is(th, td) {
  padding: 0.75rem;
  border-bottom-width: 1px;
}

table > tbody > tr:nth-of-type(2n + 1) > * {
  background-color: var(--cs-soft-white);
}

/*
    Badge
*/
.badge {
  padding: 0.4em 0.25em;
  font-size: 75%;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  text-align: center;
  vertical-align: initial;
  white-space: nowrap;
  min-width: 1.25rem;
}

.badge-soft-success {
  background-color: var(--cs-soft-success);
  color: var(--cs-success);
}

.badge-soft-warning {
  background-color: var(--cs-soft-warning);
  color: var(--cs-warning);
}

.badge-soft-primary {
  background-color: var(--cs-soft-primary);
  color: var(--cs-primary);
}

.badge-soft-info {
  background-color: var(--cs-soft-info);
  color: var(--cs-primary);
}

.badge-soft-danger {
  background-color: var(--cs-soft-danger);
  color: var(--cs-danger);
}

.badge-soft-secondary {
  background-color: var(--cs-soft-secondary);
  color: var(--cs-secondary);
}

.badge-light {
  color: var(--cs-primary);
  background-color: var(--cs-soft-white);
}

/*
    Progress
*/
.progress {
  display: flex;
  height: 0.65rem;
  overflow: hidden;
  font-size: 0.609375rem;
  background-color: #f6f6f6;
  border-radius: 0.25rem;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.625rem 0.625rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

.progress-sm {
  height: 5px;
}

.progress-lg {
  height: 12px;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.625rem;
  }
}

/*
Image
*/
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8fb;
  border: 1px solid #f6f6f6;
  max-width: 100%;
  height: auto;
}

/*
    Striped bar
*/

.striped-bar {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.625rem 0.625rem;
  -webkit-animation: 1s linear infinite bar-stripes;
  animation: 1s linear infinite bar-stripes;
}

@keyframes bar-stripes {
  0% {
    background-position-x: 0.625rem;
  }
}

/*
    Swall
*/

.swal2-actions {
  gap: 1rem;
  flex-direction: row-reverse;
}

@import url(https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --bs-heading-bg: #fff;
  --bs-topbar-search-bg: #f3f3f9;
  --bs-header-item-color: #555b6d;
  --bs-boxed-body-bg: #ebebf4;
  --bs-topnav-bg: #fff;
  --bs-menu-item-color: #545a6d;
  --bs-heading-dark-bg: #2a3042;
  --bs-input-border-color: #ced4da;
  --bs-input-focus-border-color: #b1bbc4;
  --bs-form-check-input-border: rgba(0, 0, 0, 0.1);
  --bs-footer-bg: #f2f2f5;
  --bs-footer-color: #74788d;
  --bs-custom-white: #fff;
  --bs-hr-border-color: gray-400;
}

[data-layout-mode="dark"] {
  --bs-gray-100: #212529;
  --bs-gray-200: #2a3042;
  --bs-gray-300: #32394e;
  --bs-gray-400: #a6b0cf;
  --bs-gray-500: #bfc8e2;
  --bs-gray-600: #c3cbe4;
  --bs-gray-700: #f6f6f6;
  --bs-gray-800: #eff2f7;
  --bs-gray-900: #f8f9fa;
  --bs-body-bg: #222736;
  --bs-body-bg-rgb: rgb(34, 39, 54);
  --bs-body-color-rgb: 166, 176, 207;
  --bs-body-color: #a6b0cf;
  --bs-heading-color: #f6f6f6;
  --bs-border-color: #32394e;
  --bs-custom-white: #2a3042;
  --bs-input-border-color: #32394e;
  --bs-input-focus-border-color: #3a425a;
  --bs-hr-border-color: #32394e;
  --bs-form-check-input-border: #3a425a;
  --bs-light: #32394e;
  --bs-light-rgb: 50, 57, 78;
  --bs-dark: #f6f6f6;
  --bs-dark-rgb: 246, 246, 246;
  --bs-heading-bg: #262b3c;
  --bs-header-item-color: #a6b0cf;
  --bs-topbar-search-bg: #2a3042;
  --bs-heading-dark-bg: #556ee6;
  --bs-boxed-body-bg: #32394f;
  --bs-topnav-bg: #282e3f;
  --bs-menu-item-color: #a6b0cf;
  --bs-footer-bg: #262b3c;
  --bs-footer-color: #a6b0cf;
}

[data-layout-mode="dark"] .card {
  --bs-card-bg: #2a3042;
}

[data-layout-mode="dark"] .list-group {
  --bs-list-group-bg: #2a3042;
  --bs-list-group-color: #f8f9fa;
  --bs-list-group-border-color: #32394e;
}

[data-layout-mode="dark"] .btn-light {
  --bs-btn-color: #c3cbe4;
  --bs-btn-bg: #32394e;
  --bs-btn-border-color: #32394e;
}

[data-layout-mode="dark"] .btn-outline-light {
  --bs-btn-color: #c3cbe4;
  --bs-btn-border-color: #32394e;
}

[data-layout-mode="dark"] .btn-light,
[data-layout-mode="dark"] .btn-outline-light {
  --bs-btn-hover-color: #c3cbe4;
  --bs-btn-hover-bg: #32394e;
  --bs-btn-hover-border-color: #32394e;
  --bs-btn-active-color: #c3cbe4;
  --bs-btn-active-bg: #32394e;
  --bs-btn-active-border-color: #32394e;
  --bs-btn-focus-shadow-rgb: 50, 57, 78;
}

[data-layout-mode="dark"] .btn-dark {
  --bs-btn-bg: #a6b0cf;
  --bs-btn-border-color: #a6b0cf;
  --bs-btn-hover-bg: #a6b0cf;
}

[data-layout-mode="dark"] .btn-outline-dark {
  --bs-btn-color: #a6b0cf;
  --bs-btn-border-color: #a6b0cf;
}

[data-layout-mode="dark"] .btn-dark,
[data-layout-mode="dark"] .btn-outline-dark {
  --bs-btn-hover-bg: #a6b0cf;
  --bs-btn-hover-border-color: #a6b0cf;
  --bs-btn-active-bg: #a6b0cf;
  --bs-btn-active-border-color: #a6b0cf;
  --bs-btn-focus-shadow-rgb: 195, 203, 228;
}

[data-layout-mode="dark"] .dropdown-menu {
  --bs-dropdown-bg: #2a3042;
  --bs-dropdown-link-color: #a6b0cf;
  --bs-dropdown-border-color: #32394e;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-divider-bg: #32394e;
  --bs-dropdown-link-hover-bg: #32394e;
  --bs-dropdown-link-hover-color: #e9ecef;
  --bs-dropdown-link-active-bg: #32394e;
  --bs-dropdown-link-active-color: #e9ecef;
}

[data-layout-mode="dark"] .modal {
  --bs-modal-bg: #2a3042;
}

[data-layout-mode="dark"] .offcanvas,
[data-layout-mode="dark"] .offcanvas-lg,
[data-layout-mode="dark"] .offcanvas-md,
[data-layout-mode="dark"] .offcanvas-sm,
[data-layout-mode="dark"] .offcanvas-xl,
[data-layout-mode="dark"] .offcanvas-xxl {
  --bs-offcanvas-bg: #2a3042;
}

[data-layout-mode="dark"] .nav-tabs {
  --bs-nav-tabs-link-active-bg: #2a3042;
  --bs-nav-tabs-link-active-border-color: #3a425a;
  --bs-nav-tabs-border-color: #3a425a;
  --bs-nav-tabs-link-hover-border-color: #3a425a;
  --bs-nav-tabs-link-active-color: #556ee6;
}

[data-layout-mode="dark"] .accordion {
  --bs-accordion-active-bg: rgba(85, 110, 230, 0.1);
}

[data-layout-mode="dark"] .toast {
  --bs-toast-header-bg: #2a3042;
  --bs-toast-header-color: #c3cbe4;
  --bs-toast-bg: #2a3042;
}

[data-layout-mode="dark"] .table {
  --bs-table-striped-bg: rgba(191, 200, 226, 0.05);
  --bs-table-hover-bg: rgba(191, 200, 226, 0.05);
  --bs-table-active-bg: rgba(191, 200, 226, 0.05);
}

[data-layout-mode="dark"] .table-light {
  --bs-table-color: #a6b0cf;
  --bs-table-bg: #32394e;
  --bs-table-border-color: #32394e;
}

[data-layout-mode="dark"] .table-dark {
  --bs-table-color: #a6b0cf;
  --bs-table-bg: #32394e;
  --bs-table-border-color: #3a425a;
}

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: var(--cs-primary);
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 50px;
  padding: 0 calc(24px * 0.5) 0 0;
}

.navbar-header .dropdown .show.header-item {
  background-color: var(--bs-gray-100);
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 250px;
}

.logo {
  line-height: 70px;
}

.logo .logo-sm {
  display: none;
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo span.logo-lg {
    display: none;
  }

  .logo span.logo-sm {
    display: inline-block;
  }
}

.page-content {
  padding: calc(70px + 24px) calc(24px * 0.75) 60px calc(24px * 0.75);
}

.header-item {
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff;
  border: 0;
  border-radius: 0;
}

.header-profile-user {
  height: 30px;
  width: 30px;
  padding: 3px;
  margin-right: 2px;
}

.noti-icon svg {
  font-size: 22px;
  color: #fff;
}

.noti-icon .badge {
  position: absolute;
  top: 12px;
  right: 4px;
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: var(--bs-gray-600);
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-icon-item:hover {
  border-color: var(--bs-border-color);
}

body[data-topbar="dark"] #page-topbar {
  background-color: var(--bs-heading-dark-bg);
}

body[data-topbar="dark"] .navbar-header .dropdown .show.header-item {
  background-color: rgba(255, 255, 255, 0.05);
}

body[data-topbar="dark"] .navbar-header .waves-effect .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

body[data-topbar="dark"] .header-item {
  color: #e9ecef;
}

body[data-topbar="dark"] .header-item:hover {
  color: #e9ecef;
}

body[data-topbar="dark"] .header-profile-user {
  background-color: rgba(255, 255, 255, 0.25);
}

body[data-topbar="dark"] .noti-icon svg {
  color: #e9ecef;
}

body[data-topbar="dark"] .logo-dark {
  display: none;
}

body[data-topbar="dark"] .logo-light {
  display: block;
}

body[data-topbar="dark"] .app-search .form-control {
  background-color: rgba(var(--bs-topbar-search-bg), 0.07);
  color: #fff;
}

body[data-topbar="dark"]
  .app-search
  input.form-control::-webkit-input-placeholder,
body[data-topbar="dark"] .app-search span {
  color: rgba(255, 255, 255, 0.5);
}

body[data-sidebar="dark"] .navbar-brand-box {
  background: #2a3042;
}

body[data-sidebar="dark"] .logo-dark {
  display: none;
}

body[data-sidebar="dark"] .logo-light {
  display: block;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }

  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] .navbar-brand-box {
  width: auto;
}

body[data-layout="horizontal"] .page-content {
  margin-top: 40px;
  padding: calc(55px + 24px) calc(24px * 0.5) 60px calc(24px * 0.5);
}

@media (max-width: 992px) {
  body[data-layout="horizontal"] .page-content {
    margin-top: 15px;
  }
}

.page-title-box {
  padding-bottom: 24px;
}

.page-title-box .breadcrumb {
  background-color: transparent;
  padding: 0;
}

.page-title-box h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px !important;
}

.footer {
  padding: 20px calc(24px * 0.75);
  color: var(--bs-footer-color);
  height: 60px;
  background-color: var(--bs-footer-bg);
}

@media (max-width: 991.98px) {
  .footer {
    left: 0;
  }
}

.vertical-collpsed .footer {
  left: 70px;
}

@media (max-width: 991.98px) {
  .vertical-collpsed .footer {
    left: 0;
  }
}

body[data-layout="horizontal"] .footer {
  left: 0 !important;
}

.right-bar {
  background-color: var(--bs-custom-white);
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  display: block;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 280px;
  z-index: 1050;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
}

.right-bar .right-bar-toggle {
  background-color: var(--bs-dark);
  height: 24px;
  width: 24px;
  line-height: 24px;
  display: block;
  color: var(--bs-gray-200);
  text-align: center;
  border-radius: 50%;
}

.right-bar .right-bar-toggle:hover {
  background-color: var(--bs-dark);
}

.rightbar-overlay {
  background-color: rgba(52, 58, 64, 0.55);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}

.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }

  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}

.metismenu {
  margin: 0;
}

.metismenu li {
  display: block;
  width: 100%;
}

.metismenu .mm-collapse {
  display: none;
}

.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}

.metismenu .mm-collapse.mm-show {
  display: block;
}

.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
}

.vertical-menu {
  width: 250px;
  z-index: 1001;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 50px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.main-content {
  margin-left: 250px;
  overflow: hidden;
}

.main-content .content {
  padding: 0 15px 10px 15px;
  margin-top: 70px;
}

#sidebar-menu {
  padding: 10px 0 30px 0;
}

#sidebar-menu ul li :is(a, button) {
  display: block;
  padding: .725rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#sidebar-menu ul li button {
  width: 100%;
  text-align: left;
}

#sidebar-menu ul li :is(a, button) svg {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.40rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

#sidebar-menu ul li :is(a, button):hover {
  color: #383c40;
}

#sidebar-menu ul li :is(a, button):hover svg {
  color: #383c40;
}

#sidebar-menu ul li .badge {
  margin-top: 4px;
}

#sidebar-menu ul li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 3.5rem;
  font-size: 13px;
  color: #545a6d;
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #383c40;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu {
  padding: 0;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
  font-size: 13px;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #7f8387;
  font-weight: 600;
}

.mm-active {
  color: var(--cs-primary) !important;
}

.mm-active > a {
  color: var(--cs-primary) !important;
}

.mm-active > a svg {
  color: var(--cs-primary) !important;
}

.mm-active .active svg {
  color: var(--cs-primary) !important;
}

.mm-active > svg {
  color: var(--cs-primary) !important;
}

@media (max-width: 992px) {
  .vertical-menu {
    display: none;
  }

  .main-content {
    margin-left: 0 !important;
  }

  body.sidebar-enable .vertical-menu {
    display: block;
  }
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #a6b0cf;
}

body[data-sidebar="dark"] #sidebar-menu ul li a svg {
  color: #6a7187;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
  color: #fff;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover svg {
  color: #fff;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: #79829c;
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a:hover {
  color: #fff;
}

body[data-sidebar="dark"].vertical-collpsed {
  min-height: 1760px;
}

body[data-layout="horizontal"] .main-content {
  margin-left: 0 !important;
}

body[data-sidebar-size="small"] .navbar-brand-box {
  width: 160px;
}

.topnav {
    background: var(--bs-topnav-bg);
    padding: 0 calc(24px * .5);
    -webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
    box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
    margin-top: 50px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 100
}

.topnav .topnav-menu {
    margin: 0;
    padding: 0
}

.topnav .navbar-nav .nav-link {
    font-size: 14px;
    position: relative;
    padding: 1rem 1.3rem;
    color: var(--bs-menu-item-color);
    display: flex;
    align-items: center;
}

.topnav .navbar-nav .nav-link svg {
    font-size: 15px;
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
    color: #556ee6;
    background-color: transparent
}

.topnav .navbar-nav .dropdown-item {
    color: var(--bs-menu-item-color);
    display: flex;
    align-items: center;
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
    color: #556ee6
}

.topnav .navbar-nav .nav-item .nav-link.active {
    color: #556ee6
}

.topnav .navbar-nav .dropdown.active>a {
    color: #556ee6;
    background-color: transparent
}

@media (min-width: 1200px) {

    body[data-layout=horizontal] .container-fluid,
    body[data-layout=horizontal] .navbar-header {
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .topnav .navbar-nav .nav-item:first-of-type .nav-link {
        padding-left: 0
    }

    .topnav .dropdown-item {
        padding: .5rem 1.5rem;
        min-width: 180px
    }

    .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
        left: 0;
        right: auto
    }

    .topnav .dropdown .dropdown-menu {
        margin-top: 0;
        border-radius: 0 0 .25rem .25rem
    }

    .topnav .dropdown .dropdown-menu .arrow-down::after {
        right: 15px;
        -webkit-transform: rotate(-135deg) translateY(-50%);
        transform: rotate(-135deg) translateY(-50%);
        position: absolute
    }

    .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
        position: absolute;
        top: 0 !important;
        left: 100%;
        display: none
    }

    .topnav .dropdown:hover>.dropdown-menu {
        display: block
    }

    .topnav .dropdown:hover>.dropdown-menu>.dropdown:hover>.dropdown-menu {
        display: block
    }

    .navbar-toggle {
        display: none
    }
}

.arrow-down {
  display: inline-block;
}

.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}

@media (max-width: 991.98px) {
  .navbar-brand-box .logo-dark {
    display: block;
  }

  .navbar-brand-box .logo-dark span.logo-sm {
    display: block;
  }

  .navbar-brand-box .logo-light {
    display: none;
  }

  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }

  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }

  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 15px;
    position: relative;
  }

  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl {
    width: auto;
  }

  .topnav .dropdown .dropdown-menu.dropdown-mega-menu-xl .row {
    margin: 0;
  }

  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }

  .topnav .dropdown .dropdown-item.active,
  .topnav .dropdown .dropdown-item:active {
    color: #556ee6;
  }

  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}

@media (min-width: 992px) {
  body[data-layout="horizontal"][data-topbar="light"]
    .navbar-brand-box
    .logo-dark {
    display: block;
  }

  body[data-layout="horizontal"][data-topbar="light"]
    .navbar-brand-box
    .logo-light {
    display: none;
  }

  body[data-layout="horizontal"][data-topbar="light"] .topnav {
    background-color: #556ee6;
  }

  body[data-layout="horizontal"][data-topbar="light"]
    .topnav
    .navbar-nav
    .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }

  body[data-layout="horizontal"][data-topbar="light"]
    .topnav
    .navbar-nav
    .nav-link:focus,
  body[data-layout="horizontal"][data-topbar="light"]
    .topnav
    .navbar-nav
    .nav-link:hover {
    color: rgba(255, 255, 255, 0.9);
  }

  body[data-layout="horizontal"][data-topbar="light"]
    .topnav
    .navbar-nav
    > .dropdown.active
    > a {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  background-color: #556ee6;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body[data-layout="horizontal"][data-topbar="colored"] .logo-dark {
  display: none;
}

body[data-layout="horizontal"][data-topbar="colored"] .logo-light {
  display: block;
}

body[data-layout="horizontal"][data-topbar="colored"]
  .app-search
  .form-control {
  background-color: rgba(var(--bs-topbar-search-bg), 0.07);
  color: #fff;
}

body[data-layout="horizontal"][data-topbar="colored"]
  .app-search
  input.form-control::-webkit-input-placeholder,
body[data-layout="horizontal"][data-topbar="colored"] .app-search span {
  color: rgba(255, 255, 255, 0.5);
}

body[data-layout="horizontal"][data-topbar="colored"] .header-item {
  color: #e9ecef;
}

body[data-layout="horizontal"][data-topbar="colored"] .header-item:hover {
  color: #e9ecef;
}

body[data-layout="horizontal"][data-topbar="colored"]
  .navbar-header
  .dropdown
  .show.header-item {
  background-color: rgba(255, 255, 255, 0.1);
}

body[data-layout="horizontal"][data-topbar="colored"]
  .navbar-header
  .waves-effect
  .waves-ripple {
  background: rgba(255, 255, 255, 0.4);
}

@media (min-width: 992px) {
  body[data-layout="horizontal"][data-topbar="colored"] .topnav {
    background-color: #556ee6;
  }

  body[data-layout="horizontal"][data-topbar="colored"]
    .topnav
    .navbar-nav
    .nav-link {
    color: rgba(255, 255, 255, 0.6);
  }

  body[data-layout="horizontal"][data-topbar="colored"]
    .topnav
    .navbar-nav
    .nav-link:focus,
  body[data-layout="horizontal"][data-topbar="colored"]
    .topnav
    .navbar-nav
    .nav-link:hover {
    color: rgba(255, 255, 255, 0.9);
  }

  body[data-layout="horizontal"][data-topbar="colored"]
    .topnav
    .navbar-nav
    > .dropdown.active
    > a {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

body[data-layout-size="boxed"] {
  background-color: var(--bs-boxed-body-bg);
}

body[data-layout-size="boxed"] #layout-wrapper {
  background-color: var(--bs-body-bg);
  max-width: 1300px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

body[data-layout-size="boxed"] #page-topbar {
  max-width: 1300px;
  margin: 0 auto;
}

body[data-layout-size="boxed"] .footer {
  margin: 0 auto;
  max-width: calc(1300px - 250px);
}

body[data-layout-size="boxed"].vertical-collpsed .footer {
  max-width: calc(1300px - 70px);
}

body[data-layout="horizontal"][data-layout-size="boxed"] #layout-wrapper,
body[data-layout="horizontal"][data-layout-size="boxed"] #page-topbar,
body[data-layout="horizontal"][data-layout-size="boxed"] .footer {
  max-width: 100%;
}

body[data-layout="horizontal"][data-layout-size="boxed"] .container-fluid,
body[data-layout="horizontal"][data-layout-size="boxed"] .navbar-header {
  max-width: 1300px;
}

@media (min-width: 992px) {
  body[data-layout-scrollable="true"] #page-topbar,
  body[data-layout-scrollable="true"] .vertical-menu {
    position: absolute;
  }
}

@media (min-width: 992px) {
  body[data-layout-scrollable="true"][data-layout="horizontal"] #page-topbar,
  body[data-layout-scrollable="true"][data-layout="horizontal"] .topnav {
    position: absolute;
  }
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 3.85rem;
  width: 3.85rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.avatar-group {
    padding-left: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.avatar-group .avatar-group-item {
    margin-left: -12px;
    border: 2px solid var(--bs-custom-white);
    border-radius: 50%;
    -webkit-transition: all .2s;
    transition: all .2s
}

.avatar-group .avatar-group-item:hover {
    position: relative;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px)
}

.profile-user-wid {
    margin-top: -29px
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.fw-medium {
  font-weight: 500;
}

.w-xs {
  min-width: 80px;
}

.w-sm {
  min-width: 95px;
}

.w-md {
  min-width: 110px;
}

.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}

.input-group-text {
  margin-bottom: 0;
}

input[switch] {
  display: none;
}

input[switch] + label {
  font-size: 1em;
  line-height: 1;
  width: 56px;
  height: 24px;
  background-color: #ced4da;
  background-image: none;
  border-radius: 2rem;
  padding: 0.16667rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  font-weight: 500;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

input[switch] + label:before {
  color: #343a40;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  position: absolute;
  right: 1px;
  margin: 3px;
  top: -2px;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

input[switch] + label:after {
  content: "";
  position: absolute;
  left: 3px;
  background-color: #eff2f7;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2rem;
  height: 20px;
  width: 20px;
  top: 2px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

input[switch]:checked + label {
  background-color: #556ee6;
}

input[switch]:checked + label {
  background-color: #556ee6;
}

input[switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 3px;
}

input[switch]:checked + label:after {
  left: 33px;
  background-color: #eff2f7;
}

input[switch="bool"] + label {
  background-color: #f46a6a;
}

input[switch="bool"] + label:before,
input[switch="bool"]:checked + label:before,
input[switch="default"]:checked + label:before {
  color: #fff;
}

input[switch="bool"]:checked + label {
  background-color: #34c38f;
}

input[switch="default"]:checked + label {
  background-color: #a2a2a2;
}

input[switch="primary"]:checked + label {
  background-color: #556ee6;
}

input[switch="success"]:checked + label {
  background-color: #34c38f;
}

input[switch="info"]:checked + label {
  background-color: #50a5f1;
}

input[switch="warning"]:checked + label {
  background-color: #f1b44c;
}

input[switch="danger"]:checked + label {
  background-color: #f46a6a;
}

input[switch="dark"]:checked + label {
  background-color: #343a40;
}

input[switch="dark"]:checked + label:before {
  color: #eff2f7;
}
.loader.bar {
    width: 100%;
    height: 3px;
    display: inline-block;
    position: relative;
    background: var(--cs-soft-white);
    border-radius: var(--cs-card-border-radius) var(--cs-card-border-radius) 0 0;
    overflow: hidden;
}

.loader.bar.absolute {
    position: absolute;
}

.loader.bar.rounded-none {
    border-radius: 0;
}

.loader.bar::after {
    content: '';
    width: 70%;
    height: 3px;
    background: #20a8d8;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 3s linear alternate-reverse infinite;
}


.loader.bar::before {
    content: '';
    width: 70%;
    height: 3px;
    background: #20a8d8;
    position: absolute;
    top: 0;
    right: 0;
    box-sizing: border-box;
    animation: animloader 3s linear alternate infinite;
}

@keyframes animloader {
    0% {
        left: 0;
        transform: translateX(-100%);
    }

    100% {
        left: 100%;
        transform: translateX(0%);
    }
}
.loader.ball-move {
    position: relative;
    width: 100px;
    height: 16px;
    display: block;
}
.loader.ball-move:before , .loader.ball-move:after{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--cs-soft-secondary);
    box-shadow: 32px 0 var(--cs-soft-primary);
    left: 0;
    top: 0;
    animation: ballMoveX 2s linear infinite;
}
.loader.ball-move:after {
    box-shadow: none;
    transform: translateX(64px) scale(1);
    z-index: 2;
    animation: trfLoader 2s linear infinite;
}

@keyframes trfLoader {
    0% , 5%{
        transform: translateX(64px) scale(1);
        background: var(--cs-soft-primary);
    }
    10%{
        transform: translateX(64px) scale(1);
        background: var(--cs-primary);
    }
    40%{
        transform: translateX(32px) scale(1.5);
        background: var(--cs-primary);
    }
    90%, 95% {
        transform: translateX(0px) scale(1);
        background: var(--cs-primary);
    }
    100% {
        transform: translateX(0px) scale(1);
        background: var(--cs-soft-primary);
    }
}
@keyframes ballMoveX {
    0% , 10%{  transform: translateX(0) }
    90%, 100% { transform: translateX(32px) }
}

.loader.mug {
    position: relative;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
}
.loader.mug:after{
    content: '';
    position: absolute;
    inset: 8px;
    margin: auto;
    background: var(--cs-secondary);
    border-radius: 50%;
}
.loader.mug:before{
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    background: var(--cs-primary);
    animation: crlMugLoader 2s linear infinite alternate;
}
@keyframes crlMugLoader {
    0%  ,10% { transform: translateY(40px) }
    90% , 100% { transform: translateY(0px) }
}

/**
    Wave effect
*/
.wave-effect {
    font-weight: 500;
    overflow: hidden;
    position: relative; 
}

.wave-effect * {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
}

.wave-effect::before,
.wave-effect::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.wave-effect::before {
    content: "";
    background: #FFF;
    width: 120%;
    left: -13%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.wave-effect:hover::before {
    transform: translate3d(100%, 0, 0);
}
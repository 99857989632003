@media (min-width: 600px) {
    .dropdown-menu-lg {
        width: 320px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
}

@media (max-width: 1030px) {
    .navbar-collapse {
        display: none;
    }

    .navbar-collapse.show {
        display: block;
    }
}
